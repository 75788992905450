// call in emailSubmit.js 
function sendViaEmailValidation(button){
  var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
      currentForm = $(button).closest("form"),
      to_email = currentForm.find(".to_email"),
      from_email = currentForm.find(".from_email"),
      isValid = false,
      validArr = [];

      $('.form-group input, .form-group select, .form-group textarea').removeClass('error');
      $('.form-group .error-text').hide();

      if($.trim(from_email.val()) === "") {    
        from_email.addClass('error');
        from_email.closest('.form-group').find('.error-text').show()
                  .html(I18n.t("flash.actions.email_is_blank"));
        validArr.push(false);
      }else if(!regex.test($.trim(from_email.val()))) {
        from_email.addClass('error');
        from_email.closest('.form-group').find('.error-text').show()
                  .html(I18n.t("flash.actions.email_is_no_valid"));
        validArr.push(false);
      }

      if($.trim(to_email.val()) === "") {    
        to_email.addClass('error');
        to_email.closest('.form-group').find('.error-text').show()
                .html(I18n.t("flash.actions.email_is_blank"));
        validArr.push(false);
      }else if(!regex.test($.trim(to_email.val()))) {
        to_email.addClass('error');
        to_email.closest('.form-group').find('.error-text').show()
                .html(I18n.t("flash.actions.email_is_no_valid"));
        validArr.push(false);
      }

    if(validArr.length == 0){
      return isValid = true;
    }
}

export {sendViaEmailValidation}