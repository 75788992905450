function smallNotification(type, title, textMessage, hasNotificationInsidePopup = null, context = null){
    
  var messageWindow = null;
  
  $('.small-message').removeClass('success-status warning-status error-status info-status');
  $('.small-message').find('.icon').removeClass('success-icon warning-icon error-icon info-icon');

  switch (type) {
    case "success":
      if(hasNotificationInsidePopup === true){
        $(context).closest('.window-popup').find('.small-message').addClass('success-status');
        $(context).closest('.window-popup').find('.small-message .icon').addClass('success-icon');
        messageWindow = $(context).closest('.window-popup').find('.small-message');
      }else if(hasNotificationInsidePopup === false){
        $(context).closest('.has-notification').find('.small-message').addClass('success-status');
        $(context).closest('.has-notification').find('.small-message .icon').addClass('success-icon');
        messageWindow = $(context).closest('.has-notification').find('.small-message');
      }else{
        $('.small-message').addClass('success-status');
        $('.small-message').find('.icon').addClass('success-icon');
        messageWindow = $('.small-message');
      }
      break;
    case "warning":
      if(hasNotificationInsidePopup === true){
        $(context).closest('.window-popup').find('.small-message').addClass('warning-status');
        $(context).closest('.window-popup').find('.small-message .icon').addClass('warning-icon');
        messageWindow = $(context).closest('.window-popup').find('.small-message');
      }else if(hasNotificationInsidePopup === false){
        $(context).closest('.has-notification').find('.small-message').addClass('warning-status');
        $(context).closest('.has-notification').find('.small-message .icon').addClass('warning-icon');
        messageWindow = $(context).closest('.has-notification').find('.small-message');
      }else{
        $('.small-message').addClass('warning-status');
        $('.small-message').find('.icon').addClass('warning-icon');
        messageWindow = $('.small-message');
      }
      break;
    case "error":
      if(hasNotificationInsidePopup === true){
        $(context).closest('.window-popup').find('.small-message').addClass('error-status');
        $(context).closest('.window-popup').find('.small-message .icon').addClass('error-icon');
        messageWindow = $(context).closest('.window-popup').find('.small-message');
      }else if(hasNotificationInsidePopup === false){
        $(context).closest('.has-notification').find('.small-message').addClass('error-status');
        $(context).closest('.has-notification').find('.small-message .icon').addClass('error-icon');
        messageWindow = $(context).closest('.has-notification').find('.small-message');
      }else{
        $('.small-message').addClass('error-status');
        $('.small-message').find('.icon').addClass('error-icon');
        messageWindow = $('.small-message');
      }
      break;
    default:
      if(hasNotificationInsidePopup === true){
        $(context).closest('.window-popup').find('.small-message').addClass('error-status');
        $(context).closest('.window-popup').find('.small-message .icon').addClass('error-icon');
        messageWindow = $(context).closest('.window-popup').find('.small-message');
      }else if(hasNotificationInsidePopup === false){
        $(context).closest('.has-notification').find('.small-message').addClass('error-status');
        $(context).closest('.has-notification').find('.small-message .icon').addClass('error-icon');
        messageWindow = $(context).closest('.has-notification').find('.small-message');
      }else{
        $('.small-message').addClass('error-status');
        $('.small-message').find('.icon').addClass('error-icon');
        messageWindow = $('.small-message');
      }
  }

  $('.request-message-wrapper').hide().stop();

  messageWindow.find('.message-title').html(title);
  messageWindow.find('.message-text').html(textMessage);
  
  if(hasNotificationInsidePopup != null){
    messageWindow.fadeIn(200);
  }else{
    messageWindow.fadeIn(200, function(){
      $(this).delay(5000).fadeOut(200);
    });
  }

}


export {smallNotification};