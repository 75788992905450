//call in createNewConversation.js file
function conversationValidation(button){
  var subject = $(button).closest('form').find(".conversation-subject"),
      message = $(button).closest('form').find(".conversation-message"),
      isValid = false,
      validArr = [];

      $('.form-group input, .form-group select, .form-group textarea').removeClass('error');
      $('.form-group .error-text').hide();

    if($.trim(subject.val()) === "") {    
      subject.addClass('error');
      subject.closest('.form-group').find('.error-text').show()
              .html(I18n.t("flash.actions.conversation_subject_is_blank"));
      validArr.push(false);
    }
    if($.trim(message.val()) === "") {    
      message.addClass('error');
      message.closest('.form-group').find('.error-text').show()
              .html(I18n.t("flash.actions.conversation_message_is_blank"));
      validArr.push(false);
    }

    if(validArr.length == 0){
      return isValid = true;
    }
}
export {conversationValidation};