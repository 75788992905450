function dropdownMenuCustom(self){
  
  let topPosition = $(self).offset().top,
      leftPosition = $(self).offset().left,
      getDataAttr = $(self).data().clickableMenu,

      menuWidth = $(self).parent().find('.menu-group-wrapper[data-clickable-menu = '+getDataAttr+']').width(),
      menuHeight = $(self).parent().find('.menu-group-wrapper[data-clickable-menu = '+getDataAttr+']').height();
      
  $('.menu-group-wrapper').parent().removeClass('open');
  $('.menu-group-wrapper').removeAttr('style');

  if(!$(self).parent().find('.menu-group-wrapper[data-clickable-menu = '+getDataAttr+']').parent().hasClass('open')){

      $(self).parent().find('.menu-group-wrapper[data-clickable-menu = '+getDataAttr+']').parent().addClass('open');
      $(self).parent().find('.menu-group-wrapper[data-clickable-menu = '+getDataAttr+']').css({
        "top": topPosition - menuHeight - $(self).height() + 7,
        "left": leftPosition - menuWidth + $(self).innerWidth(),
        "bottom": "auto",
        "right": "auto"
      })

  }else{
    $(self).parent().removeClass('open');
  }

  //return false;
}

export {dropdownMenuCustom};