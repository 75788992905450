function largeNotification(type, title, textMessage, timestampText ){
      
  var messageWindow = null;

  $('.large-message').removeClass('success-status error-status warning-status info-status');

  switch (type) {
    case "success":
      messageWindow = $('.large-message').addClass('success-status');
      break;
    case "warning":
      messageWindow = $('.large-message').addClass('warning-status');
      break;
    case "info":
      messageWindow = $('.large-message').addClass('info-status');
      break;
    case "error":
      messageWindow = $('.large-message').addClass('error-status');
      break;
    default:
      messageWindow = $('.large-message').addClass('error-status');
  }

  $('.request-message-wrapper').hide().stop();
  messageWindow.find('.message-title').text(title);
  messageWindow.find('.message-text').html(textMessage);
  messageWindow.find('.time-stamp').text(timestampText);
  messageWindow.fadeIn(200, function(){
      $(this).delay(5000).fadeOut(200);
  });
}

export {largeNotification};