const getCurrentSession = (name) => { 
  return sessionStorage?.getItem(name);
}

const clearCurrentSession = (name) => { 
  return sessionStorage.setItem(name, '');
}

const createSessionStorage = (array) => {
  for(let name of array ){
    if(!sessionStorage.getItem(name)) sessionStorage.setItem(name, '');
  }
}

// script for team card page, current user page (Card Collection), Search recipe page
function loadCheckboxDataFromLocalSession(sessionName){
  let checkboxes = $('input.checkbox:checkbox'),
      checkboxLength = checkboxes.length,
      getCurrentSessionValues = getCurrentSession(sessionName),
      checkboxesIDs = [];
  
  checkboxes.each(function(index, item){
    checkboxesIDs.push($(this).val())
  })
  
  checkboxesIDs.forEach(function(value, checkboxIndex){
    let isExist = getCurrentSessionValues?.split(',')?.some((item) => item == value);
    
    if(isExist){
      $('input.checkbox:checkbox[id=checkbox-'+ value +']').prop('checked', true);
    }
  })
  
  // if session has some value - need to remove disabled attr and
  // add check checkall if all was checked before
  
  if(checkboxLength == $('input.checkbox:checkbox:checked').length){
    $('#selected_all_recipe').prop('checked', true);
  }

  if(getCurrentSessionValues !== "" && getCurrentSessionValues !== null){
    $('.bulk-list').addClass('active-actions')
    $('.bulk-button').attr('disabled', false);
  }
}

// calls uf user check checkall button in Bulk section
function selectAllRecipeSession(sessionName){
  let elementIDs = sessionStorage.getItem(sessionName) !== "" ? 
                   sessionStorage.getItem(sessionName).split(',') : [];
  let checkboxes = $('input.checkbox:checkbox');
  let checkboxesIDs = [];
  
  checkboxes.each(function(index, item){
    checkboxesIDs.push($(item).val())
  })
  
  // if session is empty
  if(sessionStorage.getItem(sessionName) == ""){
    sessionStorage.setItem(sessionName, checkboxesIDs);
  }else{
    checkboxesIDs.forEach(function(value, checkboxIndex){
      let getCurrentSessionValues = sessionStorage.getItem(sessionName).split(','),
          currentID = '',
          isExist = elementIDs.some((item) => item == value);
    
      if(isExist){
        if(!$('#selected_all_recipe').prop('checked')) {
          currentID = getCurrentSessionValues.filter((item) => item != value)
          sessionStorage.setItem(sessionName, currentID);
        }
        return false;
      }else{
        currentID = [...getCurrentSessionValues, value];
        sessionStorage.setItem(sessionName, currentID);
      }
    })
  }
}

// calls uf user check checkbox
function checkRecipeSession(sessionName, self, checkboxLength, checkboxCheckedLength){
  (checkboxLength == checkboxCheckedLength) ? 
    $('#selected_all_recipe').prop('checked', true) : 
    $('#selected_all_recipe').prop('checked', false)

    let elementIDs = sessionStorage.getItem(sessionName) !== "" ? 
                sessionStorage.getItem(sessionName).split(',') : [];
      
    if($(self).prop('checked')){
      elementIDs = [...elementIDs, $(self).val()];
    }else{
      elementIDs = elementIDs.filter((item) => item != $(self).val())
    }

    sessionStorage.setItem(sessionName, elementIDs);
}

export { createSessionStorage, getCurrentSession, clearCurrentSession, loadCheckboxDataFromLocalSession, selectAllRecipeSession, checkRecipeSession}




