export function currentTime(){
  return moment().format('hh:mm:ss A');
}

// localstorage message status
export const localStorageMessageStatus = {

  setStorage: function(key, value){
    localStorage.setItem(key, value);
  },

  getStorage: function(key, title, text, specialKey = ""){
    
    let item = localStorage.getItem(key);
    if (item){
      Packs.application.largeNotification("success", I18n.t(title), I18n.t(text, { [specialKey]: item }), currentTime());
      localStorage.removeItem(key); 
    }
  }
}

export const printCardSettings = (w, h, url, windowName) => {

  let width = w,
      height = h,
      left = (screen.width - width) / 2,
      top = (screen.height - height) / 2,
      params = 'width=' + width + ', height=' + height;

      params += ', top=' + top + ', left=' + left;
      params += ', toolbar=no';
      params += ', menubar=no';
      params += ', resizable=yes';
      params += ', directories=no';
      params += ', scrollbars=no';
      params += ', status=no';
      params += ', location=no';

  let newwin = window.open(url, windowName, params);

  if (window.focus) {
    newwin.focus() 
    newwin.print();
  }
  return false;
}