function createNewConversation(self, options={}){

  let isValid = Packs.application.conversationValidation(self);

  if(isValid){
    $.ajax({
        url: options.url,
        type: options.type,
        data: options.data,
        success: function(data) {
          $(self).closest('form').find('select, input, textarea').prop('disabled', true);
          $(self).closest('form').find('label').attr('disabled', true);
          UIModules.smallNotification("success", I18n.t("flash.actions.conversation_started_title"), I18n.t("flash.actions.conversation_started_text"), true, self);
        }
    });
  }
}

export {createNewConversation};