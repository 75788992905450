function emailSubmit(element, hasSelectAll = false){
  let isValid = Packs.application.sendViaEmailValidation(element) || false;

  if(isValid){
    let title = $(element).closest('form').find('.send-via-email-title').val();
    $(element).closest('.window-popup-wrapper').modal('hide');
    $('#wait-spinner').modal('show');

    Helpers.localStorageMessageStatus.setStorage('successSendEmail', title);

    if(hasSelectAll){
      //clear all checkboxes if successfully result
      $('.select-all-checkbox, .checkbox').prop('checked', false);
      $('.bulk-list').removeClass('active-actions');
    }
  }

  return isValid;
}

export {emailSubmit}