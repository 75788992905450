function addToCollectionValidation(button){
  var collectionNewField = $(button).closest('form').find(".collection-new-field"),
      collectionSelect = $(button).closest('form').find(".collection-select"),
      isValid = false,
      validArr = [];

      $('.form-group input, .form-group select, .form-group textarea').removeClass('error');
      $('.form-group .error-text').hide();

    
     if(($.trim(collectionNewField.val()) === "") && (collectionSelect.length == 0)) {    
      collectionNewField.addClass('error');
      collectionNewField.closest('.form-group').find('.error-text').show()
                        .html(I18n.t("flash.actions.new_collection_is_blank"));
      validArr.push(false);
    }

    if(validArr.length == 0){
      return isValid = true;
    }
}

export {addToCollectionValidation}